import React from "react";
import Modal from "components/elements/modal/Modal";
import { Form } from "antd";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import UserService from "services/UserService";

export default function MappedLocation(props) {
  const { toastDispatch } = useToastContext();
  const {
    error,
    loading,
    sendHttpRequest: completeassignedFormBulk,
  } = useHttp();

  const handleData = ({ success, msg }) => {
    // props.refresh();
    props.close();
    if (success) {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    }
  };

  const onFinish = (values) => {
    if (!navigator.geolocation) {
      toastDispatch({
        type: ADD,
        payload: {
          content: "Geolocation is not supported by your browser",
          type: "danger",
        },
      });
      return false;
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      const warehouseId = UserService?.getCurrentUserLoginData()?.partnerId;

      if (!warehouseId) {
        toastDispatch({
          type: ADD,
          payload: {
            content: "Warehouse ID not found",
            type: "danger",
          },
        });
        return false;
      }
      completeassignedFormBulk(
        `update_warehouse/${props?.warehouseId}`,
        "PUT",
        {
          latitude,
          longitude,
          locationSet: true,
        },
        handleData
      );
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => console.log("form submitted")}
      onReject={props.close}
      title={`Map Location`}
      form="assignedFormBulk"
      loading={loading}
    >
      <Form
        layout="vertical"
        id="assignedFormBulk"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        Are you sure you want to Update the location ?
      </Form>
    </Modal>
  );
}
