export const salesAgentLinks = [
  {
    name: "Sell In",
    icon: "icofont-home",
    route: "/sales-agent/dashboard",
  },
  {
    name: " Sell Out History",
    icon: "icofont-chart-histogram",
    route: "/sales-agent/sell-out",
  },
  {
    name: "Accessories",
    icon: "icofont-basket",
    route: `/sales-agent/accessories`,
  },
  {
    name: "Warranty Pickup",
    icon: "icofont-vehicle-delivery-van",
    route: `/sales-agent/warranty-pickup`,
  },
];
