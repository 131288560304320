import React, { useRef, useState } from "react";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import PDFLogo from "assets/images/logo/badili-logo.png";
import { Form, Input, Radio, Checkbox, Typography, Row, Col } from "antd";
import html2pdf from "html2pdf.js";
import { formatDate } from "utils";
import { format } from "date-fns";
const { TextArea } = Input;
const { Title, Text } = Typography;

export default function ProcessWarrantyModal(props) {
  const { toastDispatch } = useToastContext();
  const pdfRef = useRef(null);
  const [form] = Form.useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedAccessories, setSelectedAccessories] = useState([]);

  const handleAccessoriesChange = (checkedValues) => {
    setSelectedAccessories(checkedValues);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const { error, loading, sendHttpRequest: processWarranty } = useHttp();
  const handleConfirmSubmit = () => {
    handleDownloadPDF();
    processWarranty(
      `process_warranty/${props?.val?._id}`,
      "PUT",
      { modeOfPayment: selectedValue,warrantyAccessories:selectedAccessories },
      ({ msg }) => {
        props.close();
        props.refresh();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      }
    );
  };

  const handleDownloadPDF = () => {
    const randomNumber = Math.floor(Math.random() * 1000); // Adjust as needed
    const filename = `badili-service-jobcard-${randomNumber}.pdf`;
    // Get the HTML content you want to convert to PDF
    const htmlContent = document.getElementById("BadiliServiceJobCard");
    // Configuration for html2pdf
    const options = {
      margin: 10,
      filename: filename,
      // image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: { mode: "avoid-all" },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    // Use html2pdf to convert HTML to PDF and download
    html2pdf().from(htmlContent).set(options).save();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => form.submit()}
      onReject={props.close}
      title={
        <>
          <b>Process Warranty</b>
          <small>
            ( Would you like to process this device&nbsp;
            <span className="text-danger">
              {props?.val?.name ?? "unknown"}
            </span>{" "}
            for warranty? )
          </small>
        </>
      }
      form="processWarranty"
      loading={loading}
      size="lg"
      AcceptText={"Submit & Download"}
    >
      <Form
        layout="vertical"
        id="processWarranty"
        initialValues={{ needDeliveryNote: "no" }}
        form={form}
        onFinish={handleConfirmSubmit}
        onFinishFailed={onFinishFailed}
      >
        <hr />
        <div
          style={{
            fontFamily: "Arial, sans-serif",
          }}
          id="BadiliServiceJobCard"
          ref={pdfRef}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "10px", lineHeight: "1.2" }}>
                <Title level={5}>
                  <b>SERVICE JOB CARD</b>
                </Title>
                <div style={{ marginTop: -15 }}>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    Emerging Ecomm Ventures Inc LTD
                  </p>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    P.O Box 8744-00100,
                    <br />
                    NAIROBI.
                  </p>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    Chiromo Courts, Waiyaki Way
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                      fontSize: "x-small",
                    }}
                  >
                    0725 62 62 62
                  </p>
                </div>
              </div>

              <div>
                <img alt="ImageName" width="118" src={PDFLogo}></img>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <label>JOB NO: {props?.val?.orderNumber}</label>
              </div>
              <div>
                <label>
                  Date In: {format(new Date(), "dd-MMM-yyyy HH:mm a")}
                </label>
              </div>
            </div>
            {/* Header */}
            <Row>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ backgroundColor: "#6AC4C6", padding: 2 }}
                >
                  CUSTOMER
                </Title>
                <div>
                  <Row>
                    <Col span={4}>
                      <p style={{ fontSize: "x-small" }}>
                        CUSTOMER: <strong> {props?.val?.cname}</strong>
                      </p>
                    </Col>
                    <Col span={4}>
                      <p style={{ fontSize: "x-small" }}>
                        CONTACT: <strong>{props?.val?.phone}</strong>
                      </p>
                    </Col>
                    <Col span={6}>
                      <p style={{ fontSize: "x-small" }}>
                        COLLECTION POINT:
                        <strong>{props?.val?.warehouse}</strong>
                      </p>
                    </Col>
                    <Col span={10}>
                      <p style={{ fontSize: "x-small" }}>
                        COLLECTION CONTACT:
                        <strong>
                          {props?.val?.sales_agent} -{" "}
                          {props?.val?.sales_agent_phone}{" "}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* Handset Details */}
            <Row>
              <Col span={24}>
                <Title
                  level={5}
                  style={{
                    backgroundColor: "#6AC4C6",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  HANDSET DETAILS
                </Title>
              </Col>
            </Row>
            <Row>
              <Row gutter={16}>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  Model: <strong>{props?.val?.modelName}</strong>
                </Col>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  Variant: <strong>{props?.val?.variant}</strong>
                </Col>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  IMEI #: <strong>{props?.val?.imei}</strong>
                </Col>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  S/N #: <strong>{props?.val?.serialNumber}</strong>
                </Col>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  Physical Damage Agreement:{" "}
                  <strong>
                    {props?.val?.physicalDamageAgreement ? "Yes" : "No"}
                  </strong>
                </Col>
                <Col span={4} style={{ fontSize: "x-small" }}>
                  Date Of Purchase:
                  <strong>{formatDate(props?.val?.soldDate)}</strong>
                </Col>
              </Row>
              <Col span={24}>
                <Title level={5}>ACCESSORIES:</Title>
                <Checkbox.Group 
                onChange={handleAccessoriesChange}
                value={selectedAccessories}
                style={{ fontSize: "x-small", marginTop: -15 }}>
                  <Checkbox value="SIMCARD" style={{ fontSize: "x-small" }}>
                    SIMCARD
                  </Checkbox>
                  <Checkbox value="CHARGER" style={{ fontSize: "x-small" }}>
                    CHARGER
                  </Checkbox>
                  <Checkbox
                    value="PHONE CASING"
                    style={{ fontSize: "x-small" }}
                  >
                    PHONE CASING
                  </Checkbox>
                  <Checkbox value="MEMORY CARD" style={{ fontSize: "x-small" }}>
                    MEMORY CARD
                  </Checkbox>
                </Checkbox.Group>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: -1 }}>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  FAULT DESCRIPTION:{" "}
                  <TextArea
                    rows={2}
                    style={{ fontSize: "x-small" }}
                    value={props?.val?.customerIssues}
                    readOnly
                  />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  REPAIR DESCRIPTION:{" "}
                  <TextArea rows={2} style={{ fontSize: "x-small" }} readOnly />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  OTHER REMARKS:{" "}
                  <TextArea rows={2} style={{ fontSize: "x-small" }} readOnly />
                </p>
              </Col>
            </Row>
            {/* Payment Section */}
            <Row gutter={16} style={{ marginTop: -10 }}>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Amount Charged: <Input readOnly />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Deposit: <Input readOnly />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Bal.: <Input readOnly />
                </p>
              </Col>
            </Row>
            {/* Mode of Payment */}
            <Row style={{ marginTop: -15 }}>
              <Col span={24}>
                <p style={{ fontSize: "x-small" }}>
                  Mode of Payment:
                  <Radio.Group
                    style={{ marginLeft: "7px", marginTop: "7px" }}
                    name="modeOfPayment"
                    onChange={handleChange}
                    value={selectedValue} // Controlled component
                  >
                    <Radio value="Cash" style={{ fontSize: "x-small" }}>
                      Cash
                    </Radio>
                    <Radio value="Mpesa" style={{ fontSize: "x-small" }}>
                      Mpesa
                    </Radio>
                  </Radio.Group>
                </p>
              </Col>
            </Row>
            <div style={{ marginTop: -15 }}>
              <Title level={5}>Collection:</Title>
              <Row gutter={16} style={{ marginTop: -10 }}>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Customer Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Staff Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Date:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: -15 }}>
              <Text italic strong style={{ color: "#6AC4C6", fontSize: "8px" }}>
                Terms and conditions
              </Text>
              <ol style={{ fontSize: "8px" }}>
                <li>
                  The above product is covered under 12 months warranty from the
                  date of purchase.
                </li>
                <li>
                  Third party software, settings and contents are not covered by
                  warranty.
                </li>
                <li>
                  Any damage caused by mishandling, like dropping, scratching,
                  liquid damage, and etc, is not covered by warranty.
                </li>
                <li>
                  Warranty will be void if the product has been opened, modified
                  or repaired by anyone other than Badili service centre.
                </li>
                <li>
                  Please show any proof of purchase like receipt for warranty
                  validation.
                </li>
                <li>
                  Out warranty product will be charged for booking and repair
                  fee.
                </li>
                <li>
                  Out warranty repair will be charged at Badili service centre's
                  normal rates.
                </li>
                <li>
                  Data will be lost during software downloading or formatting.
                </li>
                <li>
                  Any out of warranty job which is not collected within 2 months
                  will be scrapped.
                </li>
                <li>
                  Transport will be charged for in-home visits for
                  out-of-warranty repairs.
                </li>
              </ol>

              <Text
                type="secondary"
                style={{ float: "right", color: "#6AC4C6", fontSize: "8px" }}
              >
                www.badili.africa
              </Text>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
