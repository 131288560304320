import React, { useState } from "react";
import { Form, Input } from "antd";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { ADD, useToastContext } from "context/ToastContext";
import UserService from "services/UserService";

export default function MakeItSalesModel(props) {
  const { toastDispatch } = useToastContext();
  const [password, setPassword] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [form] = Form.useForm();
  const { loading: loadingPost, sendHttpRequest: addNewAsserts } = useHttp();
  const dispatchedQuantity = props?.val?.dispatchedQuantity || 0;
  const accessoryName = props?.val?.accessoryName;
  const listName = props?.val?.name;
  const handleData = ({ success, msg }) => {
    if (success) {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    }
  };

  const onFinish = (values) => {
    addNewAsserts(
      `make_sale_accessories`,
      "PUT",
      {
        id: props?.val?.id,
        partnerId: UserService?.getCurrentUserLoginData()?.partnerId,
        userId: UserService?.getCurrentUserLoginData()?.id,
        quantity,
        password,
      },
      handleData
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => console.log("form submitted")}
      onReject={props.close}
      title={"Make A Sale"}
      form="nakeAsale"
      loading={loadingPost}
      AcceptText="Submit"
    >
      <Form
        layout="vertical"
        id="nakeAsale"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <p>
          Would you like to proceed with pushing the{" "}
          <b>
            {" "}
            {listName} ({accessoryName})
          </b>{" "}
          item to sale?
        </p>
        {/* Quantity Field */}
        <Form.Item
          label="Enter Quantity"
          name="quantity"
          initialValue={1}
          rules={[
            {
              required: true,
              message: "Please input the quantity",
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject("Quantity must be greater than zero"),
            },
            {
              validator: (_, value) =>
                value <= dispatchedQuantity
                  ? Promise.resolve()
                  : Promise.reject(
                      `Quantity cannot exceed dispatched quantity (${dispatchedQuantity})`
                    ),
            },
          ]}
        >
          <Input
            type="number"
            min={1}
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            placeholder="Enter quantity"
          />
        </Form.Item>
        <hr></hr>
      
        <Form.Item
          label="Enter your password to authorize"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
          ]}
        >
          <Input
            type="password"
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
