export const WarrantyLinks = [
  {
    name: "Pickup Request",
    icon: "icofont-home",
    route: "/warranty/dashboard",
  },
  {
    name: "Customer Approval",
    icon: "icofont-user",
    route: "/warranty/customer-approval",
  },
  {
    name: "Warranty Collection",
    icon: "icofont-info",
    route: "/warranty/warranty-collection",
  },
];
