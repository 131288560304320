import React from "react";
import Modal from "components/elements/modal/Modal";
import { Form } from "antd";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import UserService from "services/UserService";

export default function CustomerRejectModal(props) {
  const {
    error: trnasferOrderError,
    loading,
    sendHttpRequest: push_to_transfer_order,
  } = useHttp();
  const { toastDispatch } = useToastContext();

  const onFinish = (values) => {
    let data = {
      id: props?.id,
      userId: UserService.getCurrentUserLoginData().id,
    };

    push_to_transfer_order(
      `client_warranty_diagnosis_rejection`,
      "PUT",
      data,
      ({ msg, success }) => {
        if (success) {
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          props.refresh();
        } else {
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "danger",
            },
          });
        }
      }
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => console.log("form submitted")}
      onReject={props.close}
      title={
        <>
          <small>
            Customer Reject{" "}
            <b>
              {props?.name} ({props?.imei})
            </b>
          </small>
        </>
      }
      form="CustomerApprovedForm"
      AcceptText="Reject"
      loading={loading}
    >
      <hr />
      <Form
        layout="vertical"
        id="CustomerApprovedForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        Are you sure you want to Reject this item for the customer?
      </Form>
    </Modal>
  );
}
