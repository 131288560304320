import React from "react";
import WarrantyPickup from "./WarrantyPickup";

export const WarrantyPickupRoutes = [
  {
    path: `/warranty-pickup`,
    exact: true,
    element: <WarrantyPickup />,
  },
];
