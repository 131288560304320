import { AccessoriesRoutes } from "./modules/Accessories/AccessoriesRoutes";
import SellInRoutes from "./modules/sellIn/SellInRoutes";
import SellOutRoute from "./modules/sellOut/SellOutRoutes";
import { WarrantyPickupRoutes } from "./modules/warranty-pickup/WarrantyPickupRoutes";

export const SalesAgentRoutes = [
  ...SellInRoutes,
  ...SellOutRoute,
  ...AccessoriesRoutes,
  ...WarrantyPickupRoutes
];
